import { NavigateFunction, NavigateOptions, To } from 'react-router-dom';

export const redirectTo = (path: To, navigate: NavigateFunction, options?: NavigateOptions) =>
  options ? navigate(path, options) : navigate(path);

export const checkAuthorizationAccess = (
  userPermissions: string[],
  requiredPermissions: string[]
): boolean => {
  if (!requiredPermissions?.length) {
    return true;
  }
  return userPermissions.some((permission) => requiredPermissions.includes(permission));
};

export function formatNameAndId(id: string, name?: string): string {
  return name ? `#${id} - ${name}` : `#${id}`;
}

/*
 * Truncates a string to a specified maximum length, preserving
 * the beginning and end of the string.
 * If the string is longer than maxLength, it will be truncated
 * and an ellipsis will be added in the middle.
 * */
export function truncateString(str: string, maxLength: number, endLength: number) {
  // If the string is shorter than or equal to maxLength, return it as is.
  if (str.length <= maxLength) {
    return str;
  }

  const ellipsis = '..';
  // Adjust the maxLength to account for the ellipsis.
  const adjustedMaxLength = maxLength - ellipsis.length;

  // If the adjustedMaxLength is zero or negative,
  // return only the ellipsis followed by the end of the string.
  if (adjustedMaxLength <= 0) {
    return `${ellipsis}${str.slice(-endLength)}`;
  }

  // Calculate the length of the start part of the string to keep.
  const startLength = Math.max(adjustedMaxLength - endLength, 0);

  // Get the start and end parts of the string.
  const start = str.substring(0, startLength);
  const end = str.substring(str.length - endLength);

  // Combine the start, ellipsis, and end to form the truncated string.
  return `${start}${ellipsis}${end}`;
}
