import { Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DistinctConnectionLabelValue } from '../../../interfaces/distinct-product-label-value';
import { getDistinctSetModelsFilter } from '../../../services/api/set-model-api';
import { globalDistinctConnection } from '../../../services/functions/globalDistinctConnection';
import { VegWebsiteStatus } from '../../../utils/constants/booking';
import {
  mapToProductFilterFromArrays,
  removeFalsyPropertyFromArrays,
  toLabelValues,
} from '../../../utils/functions';
import { FormMultiSelect } from '../../shared/form-components/FormMultiSelect';

export function SetModelsManagementFilter() {
  const { t } = useTranslation('gauge');
  const { control } = useFormContext();
  const [distinctSetConnectionOptions, setDistinctSetConnectionOptions] = React.useState({
    connection: [],
    od_inch: [],
    end: [],
    min_weight_max_weight: [],
    application: [],
    option: [],
  } as DistinctConnectionLabelValue);

  const websiteStatusOptions = toLabelValues(VegWebsiteStatus, 'websiteStatus.');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        handleChangeDistinctConnection();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: SetsManagementFilter.tsx ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, []);

  const handleChangeDistinctConnection = React.useCallback(async () => {
    const { _formValues } = control;
    const filter = removeFalsyPropertyFromArrays(
      mapToProductFilterFromArrays(_formValues) as Record<string, any[]>
    );

    const { connection } = await globalDistinctConnection(getDistinctSetModelsFilter, filter);
    setDistinctSetConnectionOptions(connection);
  }, []);

  return (
    <Grid
      sx={{
        '&.MuiGrid-root': {
          backgroundColor: 'inherit',
        },
      }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={2}>
        <FormMultiSelect
          id="connection-select"
          name="connection"
          label={`${t('details.connection')}`}
          options={distinctSetConnectionOptions?.connection}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="od-select"
          name="od_inch"
          label={`${t('details.od')}`}
          options={distinctSetConnectionOptions?.od_inch}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="min-weight-max-weight-select"
          name="min_weight_max_weight"
          label={`${t('details.minWeightMaxWeight')}`}
          options={distinctSetConnectionOptions?.min_weight_max_weight}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="end-select"
          name="end"
          label={t('details.end')}
          options={distinctSetConnectionOptions?.end}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="application-select"
          name="application"
          label={t('details.application')}
          options={distinctSetConnectionOptions?.application}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="option-select"
          name="option"
          label={t('details.option')}
          options={distinctSetConnectionOptions?.option}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="set-webstatus-select"
          name="website_status"
          label={t('setsManagement.webStatus')}
          options={websiteStatusOptions}
          withUniqueSelection={true}
          nsTranslate="common"
        />
      </Grid>
    </Grid>
  );
}
