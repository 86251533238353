import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';

function DashboardAdmin() {
  return (
    <ContainerOutlet>
      <h3>Todo</h3>
    </ContainerOutlet>
  );
}

export default DashboardAdmin;
