import {
  InformationBox,
  LabelValue,
  SectionTitle,
  SimpleButton,
} from '@digital-at-vallourec/steel-design-system-react';
import { FileDownload, LinkOff } from '@mui/icons-material';
import { DialogActions, Grid } from '@mui/material';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux';
import { HttpVerbs } from '../../../interfaces/api';
import {
  ReservationDetailSetsGrid,
  SetBookingInformations,
  UpdateBookingParams,
} from '../../../interfaces/veg';
import {
  exportCalibrationCertificate,
  exportGpsGaugingSummary,
  getSetBookingInformations,
  updateBooking,
} from '../../../services/api/veg-api';
import { selectProductQualifications } from '../../../services/store/licenseeContextSlice';
import { selectUser, selectUserId } from '../../../services/store/userSlice';
import { $darkGreen, $darkRed, $green, $orange, $primaryBrand } from '../../../styles/colors';
import { BookingStatus, HYPERMEDIA_NAME } from '../../../utils';
import {
  getHrefHypermedia,
  getProductsInJson,
  productToQualification,
} from '../../../utils/functions';
import {
  SetBookingInformationsValues,
  getSetBookingInformationsValues,
} from '../../../utils/functions/set-creation';
import ShowApplicabilities from '../../set/sets-management/ShowApplicabilities';
import { ContainerCard, MenuOptionsProps, VamDialog, VamMoreOptions } from '../../shared';
import ExpeditionStatus from './ExpeditionStatus';
import ShowChangeSet from './ShowChangeSet';

interface ShipmentProps {
  booking_id: number;
  updateIsSetRelated: Function;
  bidentPrice: number;
  totalPrice: number;
  bookingStatus: string;
}

export function Shipment({
  booking_id,
  updateIsSetRelated,
  bidentPrice,
  totalPrice,
  bookingStatus,
}: ShipmentProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'manageBookingDetail.shipment' });
  const { t: tCommon } = useTranslation('common');
  const [setBookingInformations, setSetBookingInformation] =
    React.useState<SetBookingInformations>();
  const [openChangeSet, setOpenChangeSet] = React.useState(false);
  const [isPopinOpen, setIsPopinOpen] = React.useState(false);
  const [preSelectedSet, setPreSelectedSet] = React.useState<number>(null);
  const [setBookingValues, setSetBookingValues] = React.useState<SetBookingInformationsValues[]>(
    []
  );
  const [openApplicabilities, setOpenApplicabilities] = React.useState(false);
  const [hrefApplicabilities, setHrefApplicabilities] = React.useState<string>();
  const { licenseeNumber } = useAppSelector(selectUser);
  const products = useAppSelector(selectProductQualifications);
  const userId = useAppSelector(selectUserId);
  const { setValue, getValues } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    fetchSetBookingInformations();
  }, []);

  const fetchSetBookingInformations = async () => {
    try {
      const { data } = await getSetBookingInformations(booking_id);
      if (data?.set_id) {
        setPreSelectedSet(data?.set_id);
        updateIsSetRelated(true);
        enqueueSnackbar(t('fetchSetBookingSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('fetchSetBookingWarning'), {
          preventDuplicate: true,
          variant: 'warning',
        });
      }
      setSetBookingInformation(data);
      setSetBookingValues(getSetBookingInformationsValues(data, t));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('fetchSetBookingError'), {
        preventDuplicate: true,
        variant: 'error',
      });
    }
  };

  /* istanbul ignore next */
  const updateBookingSet = async (linkSet: boolean, setId?: number, oldSetId?: number) => {
    const values = getValues();
    const for_qualification = productToQualification(values.connection, values.end, products);

    try {
      const updateBookingData: UpdateBookingParams = {
        set_id: setId,
        old_set_id: oldSetId,
        modification_date: new Date(),
        user_id: userId,
        licensee_number: licenseeNumber,
        start_date: values.start_date,
        end_date: values.end_date,
        comments: values.comments,
        products: getProductsInJson(values),
        bident: values.bident,
        bident_price: bidentPrice,
        total_price: totalPrice,
        for_qualification,
        status: bookingStatus,
      };

      const { data } = await updateBooking(booking_id, updateBookingData);

      if (!data?.set_id && !linkSet) {
        updateIsSetRelated(false);
        enqueueSnackbar(t('unlinkSetSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      } else if (data?.set_id && linkSet) {
        updateIsSetRelated(true);
        enqueueSnackbar(t('linkSetSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('linkUnlinkSetError'), {
          preventDuplicate: true,
          variant: 'error',
        });
      }
      setPreSelectedSet(data?.set_id);
      setValue('set_id', data?.set_id);
      setSetBookingInformation({
        ...setBookingInformations,
        set_id: data?.set_id,
        gauge_location: data?.gauge_location,
        gauge_weight: data?.gauge_weight,
        case_weight: data?.case_weight,
        total_weight: data?.total_weight,
        usage_rate: data?.usage_rate,
        stock: data?.stock,
      });
      setSetBookingValues(getSetBookingInformationsValues(data, t));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('linkUnlinkSetError'), {
        preventDuplicate: true,
        variant: 'error',
      });
    }
  };

  /* istanbul ignore next */
  const optionsMenu: MenuOptionsProps[] = [
    {
      label: t('selectSet', { context: preSelectedSet ? 'another' : '' }),
      handleItemClick: () => {
        setOpenChangeSet(true);
      },
    },
    {
      label: t('removeSet'),
      disabled: !preSelectedSet,
      handleItemClick: () => setIsPopinOpen(true),
    },
    {
      label: t('seeApplicabilities'),
      disabled: !preSelectedSet,
      handleItemClick: () => {
        const href = getHrefHypermedia(
          setBookingInformations.actions,
          HYPERMEDIA_NAME.setCreationApplicabilities,
          HttpVerbs.GET
        );
        setHrefApplicabilities(href);
        setOpenApplicabilities(true);
      },
    },
  ];

  const handleConfirmChangeSet = (setSelected: ReservationDetailSetsGrid) => {
    updateBookingSet(true, setSelected.set_id, preSelectedSet);
    // // TODO add rules when we changes connections params
  };

  /* istanbul ignore next */
  const handleUnlinkConfirm = () => {
    updateBookingSet(false, null, preSelectedSet);
    setIsPopinOpen(false);
  };

  const downloadCalibrationCertificate = () => {
    exportCalibrationCertificate(setBookingInformations?.set_id)
      .then(({ data }) => {
        saveAs(
          data,
          `calibration_certificate_document_set_n_${setBookingInformations?.set_id}.pdf`
        );
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  const downloadGpsGaugingSummary = () => {
    exportGpsGaugingSummary(booking_id)
      .then(({ data }) => {
        saveAs(data, `gps_gauging_summary_booking_n_${booking_id}.pdf`);
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
      });
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      noPaddingTop
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
      dataTestId="shipment-section"
    >
      <ShowApplicabilities
        isOpen={openApplicabilities}
        setIsOpen={setOpenApplicabilities}
        hrefUrl={hrefApplicabilities}
      />
      <ShowChangeSet
        isOpen={openChangeSet}
        setIsOpen={setOpenChangeSet}
        handleChangeSet={handleConfirmChangeSet}
        preSelectedSet={preSelectedSet}
      />
      <VamDialog
        title={t('confirmUnlink')}
        headerIcon={<LinkOff fontSize="large" htmlColor={$primaryBrand} />}
        isOpen={isPopinOpen}
        handleClose={/* istanbul ignore next */ () => setIsPopinOpen(false)}
        withCloseButton
        dialogActions={
          <DialogActions className="!tw-justify-center">
            <SimpleButton
              variant="no-background-primary"
              onClick={/* istanbul ignore next */ () => setIsPopinOpen(false)}
            >
              {t('cancelUnlinkButton')}
            </SimpleButton>
            <SimpleButton variant="cta" onClick={handleUnlinkConfirm}>
              {t('confirmUnlinkButton')}
            </SimpleButton>
          </DialogActions>
        }
      />
      <Grid xs={12} container rowSpacing={2}>
        <Grid item xs={6} data-testid="shipment-section-set-booking-informations">
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <InformationBox
                color={setBookingInformations?.set_id ? $green : $darkRed}
                data={setBookingValues}
                justifyContentChildren="space-between"
              >
                <VamMoreOptions options={optionsMenu} data-testid="vam-menu-change-set-actions" />
              </InformationBox>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="start">
              <LabelValue
                label={t('labelValue.caseWeight')}
                value={setBookingInformations?.case_weight?.toString() || '-'}
                isUpperCaseLabel
                labelWeight={500}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <LabelValue
                label={t('labelValue.gaugeWeight')}
                value={setBookingInformations?.gauge_weight?.toString() || '-'}
                isUpperCaseLabel
                labelWeight={500}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="start">
              <LabelValue
                label={t('labelValue.totalWeight')}
                value={setBookingInformations?.total_weight?.toString() || '-'}
                isUpperCaseLabel
                labelWeight={500}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <LabelValue
                label={t('labelValue.gaugeLocation')}
                value={setBookingInformations?.gauge_location || '-'}
                isUpperCaseLabel
                labelWeight={500}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={12}>
              {bookingStatus !== BookingStatus.Gps ? (
                <InformationBox
                  color={setBookingInformations?.set_id ? $darkGreen : $orange}
                  data={[
                    {
                      title: t('calibrationCertificateTitle'),
                      name: t('calibrationCertificateSubTitle'),
                    },
                  ]}
                  justifyContentChildren="space-between"
                >
                  <SimpleButton
                    size="small"
                    variant="icon-secondary-dark-blue"
                    data-testid="export-calibration-certificate-btn"
                    onClick={downloadCalibrationCertificate}
                    disabled={!setBookingInformations?.set_id}
                  >
                    <FileDownload />
                  </SimpleButton>
                </InformationBox>
              ) : (
                <InformationBox
                  color={$darkGreen}
                  data={[
                    {
                      title: t('gaugingProcedureTitle'),
                      name: t('gaugingProcedureSubTitle'),
                    },
                  ]}
                  justifyContentChildren="space-between"
                >
                  <SimpleButton
                    size="small"
                    variant="icon-secondary-dark-blue"
                    data-testid="export-gauging-summary-btn"
                    onClick={downloadGpsGaugingSummary}
                  >
                    <FileDownload />
                  </SimpleButton>
                </InformationBox>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* second right bloc */}
        <Grid item xs={6} data-testid="expedition-status-booking">
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <ExpeditionStatus bookingId={booking_id} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
