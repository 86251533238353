import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import { Grid, Switch } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { $lightBlue } from '../../styles/colors';
import {
  REQUIRED_FIELD,
  decimalNumberRegex,
  priceRegex,
} from '../../utils/constants/validators-message';
import { FormTextField } from '../shared';

interface AddGaugeModelProps {
  formProps?: UseFormReturn<any, any>;
}

const StyledGrid = styled(Grid)`
  background-color: ${$lightBlue};
  border-radius: 4px;
  margin-left: 46px;
  margin-right: 46px;
  padding: 20px;
  width: 640px;
  min-height: 340px;

  .MuiGrid-item {
    padding-left: 16px;
  }
`;

const SwitchContainer = styled.div`
  align-items: center;
  padding: 8px 0;
  display: flex;
  gap: 10px;
  height: 100%;
  width: 100%;
`;

export function AddGaugeModel(props: AddGaugeModelProps) {
  const { t } = useTranslation('gauge', { keyPrefix: 'setModel.gaugeModel' });
  return (
    <StyledGrid
      data-testid="add-gauge-model-dialog-form"
      container
      columnSpacing={4}
      rowSpacing={4}
    >
      <Grid item xs={6}>
        <FormTextField
          withFixedLabel
          inputProps={{ 'data-testid': 'add-gauge-model-designation-input' }}
          labelValue={`${t('designation')} *`}
          name="designation_vam"
          requiredMessage={REQUIRED_FIELD}
          placeholder="Fill designation"
        />
      </Grid>
      <Grid item xs={6}>
        <SwitchContainer>
          <Switch
            data-testid="add-gauge-model-immobilisable-switch"
            name="immobilisable"
            {...props.formProps?.register('immobilisable')}
          />
          <Typo variant="subtitle1" fontSize={12}>
            <Trans components={{ bold: <strong /> }}>{t('immobilisable')}</Trans>
          </Typo>
        </SwitchContainer>
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          withFixedLabel
          inputProps={{ 'data-testid': 'add-gauge-model-drawing-input' }}
          labelValue={`${t('drawingNumber')} *`}
          name="drawing_number"
          requiredMessage={REQUIRED_FIELD}
          placeholder="Fill drawing number"
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          withFixedLabel
          inputProps={{ 'data-testid': 'add-gauge-model-reference-input' }}
          labelValue={`${t('referenceNumber')} *`}
          name="reference_number"
          requiredMessage={REQUIRED_FIELD}
          placeholder="Fill reference number"
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          withFixedLabel
          inputProps={{ 'data-testid': 'add-gauge-model-weight-input' }}
          labelValue={`${t('weight')} *`}
          name="weight"
          requiredMessage={REQUIRED_FIELD}
          patternValue={decimalNumberRegex}
          patternMessage={t('fieldTypeError')}
          placeholder="Fill weight"
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          withFixedLabel
          inputProps={{ 'data-testid': 'add-gauge-model-price-input' }}
          labelValue={`${t('price')} *`}
          name="price"
          requiredMessage={REQUIRED_FIELD}
          patternValue={priceRegex}
          patternMessage={t('fieldTypeError')}
          placeholder="Fill price"
        />
      </Grid>
    </StyledGrid>
  );
}
