import { InformationBox, SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Grid } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { DistinctProductLabelValue } from '../../../interfaces/distinct-product-label-value';
import { BookingPriceParams, GaugesInformationProps } from '../../../interfaces/veg';
import {
  fetchDistinctProduct,
  getBookingPrice,
  getBookingStock,
  getBookingStocks,
  updateBookingDepot,
} from '../../../services/api/veg-api';
import { publish } from '../../../services/core/event';
import {
  $darkGreen,
  $darkRed,
  $lightBlue,
  $lightestRed,
  $primaryBlack,
  $primaryNavy,
} from '../../../styles/colors';
import { DeliveryMode } from '../../../utils';
import { BOOKING_QUALIFICATION_EVENT } from '../../../utils/constants/custom-event';
import {
  formatDate,
  getDateFromString,
  getDifferenceInDays,
  mapToProductFilter,
  removeFalsyProperty,
  toOptionsFilters,
} from '../../../utils/functions';
import { computeTotalPrice } from '../../../utils/functions/price';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { DialogComponent } from '../../setModel/DialogComponent';
import {
  ContainerCard,
  FormDatePicker,
  FormSelect,
  FormSwitchField,
  FormTextField,
  PriceBox,
} from '../../shared';
import { GaugeStockByLocationUi } from '../booking-gauge/GaugesInformation';
import {
  AddBidentPrice,
  applyShowBidentPrice,
  canShowBidentBox,
  checkSearchSetsParams,
  gaugeStockLocationCols,
  getSelectedGaugeLocation,
  isLocationDialogButtonDisabled,
  mappingStockAvailable,
} from '../utils';

function GaugeDetails({
  handleChangePrice,
  bidentPrice,
  totalPrice,
  showBident,
  setShowBident,
  defaultBidentChecked,
  isSetRelated,
  gaugeLocation,
  isReplacementLtr,
}: GaugesInformationProps) {
  const { t } = useTranslation('gauge');
  const { enqueueSnackbar } = useSnackbar();
  const { control, setValue, getValues } = useFormContext();
  const [minEndDate, setMinEndDate] = React.useState<Date>(null);
  const [maxStartDate, setMaxStartDate] = React.useState<Date>(null);
  const [fetchAvailableStock, setFetchAvailableStock] = React.useState<boolean>(false);
  const [availableStock, setAvailableStock] = React.useState<number[]>([]);
  const [isGaugeSelectorOpen, setIsGaugeSelectorOpen] = React.useState<boolean>(false);
  const [gaugeStockLocation, setGaugeStockLocation] = React.useState<GaugeStockByLocationUi[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [distinctProductLabelValue, setDistinctProduct] = React.useState({
    connection: [],
    od_inch: [],
    weight_thickness: [],
    end: [],
    application: [],
    option: [],
  } as DistinctProductLabelValue);

  const navigate = useNavigate();

  useEffect(() => {
    setMinEndDate(getValues('start_date'));
    setMaxStartDate(getValues('end_date'));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        handleChangeDistinctProduct();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [totalPrice]);

  /* istanbul ignore next */
  const handlePrices = async () => {
    const {
      _formValues: { connection, od_inch, rental_type, bident },
    } = control;
    setValue('bident', false);
    if (connection && (od_inch || canShowBidentBox(connection))) {
      const {
        data: { veg_price, bident_price },
      } = await getBookingPrice(
        removeFalsyProperty({ connection, od_inch, rental_type }) as BookingPriceParams
      );
      const totalPrice = bident ? veg_price + bident_price : veg_price;
      handleChangePrice(totalPrice, bident_price);
    } else {
      handleChangePrice(0, 0);
    }
  };

  /* istanbul ignore next */
  const handleSwitchBident = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('bident', event.target.checked);
    handleChangePrice(
      computeTotalPrice(event.target.checked, totalPrice, bidentPrice),
      bidentPrice
    );
  };

  const handleChangeDistinctProduct = useCallback(async (_?: any, controlName?: string) => {
    const { _formValues } = control;
    // for perf we execute showBidentPrice handlePrices only in certains cdts
    if (controlName === 'connection') {
      applyShowBidentPrice(setShowBident, setValue, _formValues.connection);
    }
    if (controlName === 'connection' || controlName === 'od_inch') {
      handlePrices();
    }
    if (controlName === 'connection' || controlName === 'end') {
      const { connection, end } = _formValues;
      if (connection && end) {
        publish(BOOKING_QUALIFICATION_EVENT, { connection, end });
      }
    }
    handleStockAvailable();
    const filter = removeFalsyProperty(mapToProductFilter(_formValues));
    try {
      const { data } = await fetchDistinctProduct(filter);
      const labelValueOptions = toOptionsFilters(data);
      setDistinctProduct(labelValueOptions);
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }, []);

  /* istanbul ignore next */
  const handleStockAvailable = async () => {
    setAvailableStock([]);
    const { _formValues } = control;
    const canFetchStock = checkSearchSetsParams(_formValues);
    setFetchAvailableStock(canFetchStock);

    if (canFetchStock) {
      const params = mappingStockAvailable(_formValues);
      try {
        const { data } = await getBookingStock(params);
        setAvailableStock(data);
        enqueueSnackbar(t('fetchStockSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('fetchStockFailed'), {
          preventDuplicate: true,
          variant: 'error',
        });
      }
    }
  };

  /* istanbul ignore next */
  const handleOpenDialogGaugeLocation = async () => {
    setIsGaugeSelectorOpen(true);
    const { _formValues } = control;
    const params = mappingStockAvailable(_formValues);
    try {
      const { data } = await getBookingStocks(params);
      const g_location_id = _formValues.gauge_location_id;
      setGaugeStockLocation(
        data.map((el) => {
          return {
            ...el,
            next_available_date: el.next_available_date
              ? formatDate(el.next_available_date, false)
              : null,
            selected: getSelectedGaugeLocation(el.id, g_location_id),
            grid_status: el.quantity ? 'OK' : el.next_available_date ? 'KO' : 'N/A',
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  /* istanbul ignore next */
  const handleSetGauge = async () => {
    const selectedRow = gaugeStockLocation.filter((el) => el.selected)[0];
    setLoading(true);
    try {
      if (selectedRow.town !== gaugeLocation) {
        await updateBookingDepot({
          booking_id: getValues('booking_id'),
          gauge_location_id: selectedRow.id,
        });
        Swal.fire({
          title: t('details.alertTitle'),
          text: t('details.alertDescription'),
          icon: 'success',
          confirmButtonText: t('details.alertButton'),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error while updating depot', {
        preventDuplicate: true,
        variant: 'error',
      });
    } finally {
      setLoading(false);
      setIsGaugeSelectorOpen(false);
    }
  };

  /* istanbul ignore next */
  const handleOnSelectionModelChange = (selectionIndex: GridRowSelectionModel) => {
    // set the checkbox
    gaugeStockLocation.forEach((row) => {
      row.selected = row.id === selectionIndex[selectionIndex.length - 1];
    });
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('manageBookingDetail.gaugeDetails.title')}
          description={t('manageBookingDetail.gaugeDetails.description')}
        />
      }
      actionHeaderNode={<PriceBox price={totalPrice} />}
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={6}>
          <FormDatePicker
            label={`${t('details.startDate')}*`}
            name="start_date"
            handleChange={(startDate: Date) => {
              setMinEndDate(startDate);
              const endDate = getDateFromString(control._formValues.end_date);
              setValue('duration', getDifferenceInDays(startDate, endDate));
            }}
            maxDate={maxStartDate}
            dataTestId="datepicker-startdate"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            label={`${t('details.endDate')}*`}
            name="end_date"
            minDate={minEndDate}
            handleChange={(endDate: Date) => {
              setMaxStartDate(endDate);
              const startDate = getDateFromString(getValues('start_date'));
              setValue('duration', getDifferenceInDays(startDate, endDate));
            }}
            dataTestId="datepicker-enddate"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker disabled label={`${t('details.creationDate')}*`} name="creation_date" />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="business_number"
            name="business_number"
            label={`${t('details.businessNumber')}*`}
            InputLabelProps={{ shrink: !!getValues('business_number') }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="duration"
            name="duration"
            label={`${t('details.duration')}*`}
            InputLabelProps={{ shrink: !!getValues('duration') }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="connection-select"
            variant="standard"
            data-testid="connection-select-testid"
            name="connection"
            label={`${t('details.connection')}*`}
            options={distinctProductLabelValue?.connection}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="od-select"
            variant="standard"
            name="od_inch"
            label={`${t('details.od')}*`}
            options={distinctProductLabelValue?.od_inch}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="weight-select"
            variant="standard"
            label={`${t('details.weightTickness')}*`}
            name="weight_thickness"
            options={distinctProductLabelValue?.weight_thickness}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="end-select"
            variant="standard"
            name="end"
            label={`${t('details.end')}*`}
            options={distinctProductLabelValue?.end}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="application-select"
            variant="standard"
            name="application"
            label={`${t('details.application')}*`}
            options={distinctProductLabelValue?.application}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            id="option-select"
            variant="standard"
            name="option"
            label={`${t('details.option')}*`}
            options={distinctProductLabelValue?.option}
            handleChange={handleChangeDistinctProduct}
            disabled={isSetRelated}
          />
        </Grid>

        {showBident ? (
          <Grid item xs={12}>
            <FormSwitchField
              name="bident"
              label="Bident"
              onChange={handleSwitchBident}
              data-testid="switch-bident-testid"
              role="switch-bident-role"
              defaultChecked={defaultBidentChecked}
            />
            {AddBidentPrice(t('details.bidentInfo'), bidentPrice)}
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <FormTextField
            sx={{
              width: '100%',
            }}
            id="comments-multiline-static"
            name="comments"
            label={t('details.comments')}
            multiline
            rows={4}
            placeholder={t('comments_placeholder')}
            variant="outlined"
          />
        </Grid>
        {getValues('delivery_mode') === DeliveryMode.Delivery ? (
          <Grid item xs={12}>
            <InformationBox
              mainTitle={t('details.informationBoxTitle')}
              backgroundColor={
                fetchAvailableStock && !availableStock?.length ? $lightestRed : $lightBlue
              }
              color={fetchAvailableStock && !availableStock?.length ? $darkRed : $primaryNavy}
              data={[
                {
                  color: !fetchAvailableStock
                    ? $primaryBlack
                    : availableStock?.length
                    ? $darkGreen
                    : $darkRed,
                  title: t('details.stockWorldwide'),
                  name: fetchAvailableStock ? availableStock?.length : '-',
                },
                {
                  title: t('details.selectedLocation'),
                  name: gaugeLocation || '-',
                },
              ]}
              justifyContentChildren="space-between"
            >
              <DialogComponent
                isOpen={isGaugeSelectorOpen}
                searchLabelBtn={t('details.searchSelectSet')}
                title={t('details.dialogTitle')}
                subTitle={t('details.dialogSubTitle')}
                buttonProps={{
                  disabled: isLocationDialogButtonDisabled({
                    fetchAvailableStock,
                    isReplacementLtr,
                    isSetRelated,
                    status: getValues('status'),
                  }),
                  variant: 'secondary-dark',
                  className: '!tw-ml-12',
                }}
                dataTestIdButton="select-gauge"
                fullWidth
                confirmSelection={handleSetGauge}
                handleCloseDialog={
                  /* istanbul ignore next */ () => {
                    setIsGaugeSelectorOpen(false);
                    setGaugeStockLocation([]);
                  }
                }
                handleOpenDialog={handleOpenDialogGaugeLocation}
              >
                <VamDataGrid
                  showRowLeftThickBorder
                  disableColumnFilter
                  columns={gaugeStockLocationCols}
                  rows={gaugeStockLocation}
                  onRowSelectionModelChange={handleOnSelectionModelChange}
                  loading={loading}
                />
              </DialogComponent>
            </InformationBox>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </ContainerCard>
  );
}

export default GaugeDetails;
