import { GridSortModel } from '@mui/x-data-grid';

import { GridStatus } from '../../components/data-grid/utils';

export const LTR_SIX_MONTHS_DAYS = 185;

export enum DeliveryMode {
  Delivery = 'DELIVERY',
  ClicknCollect = 'CLICK_AND_COLLECT',
}

export const MAP_INDEX_DELIVERY = {
  delivery: 0,
  click_and_collect: 1,
};

export enum RentalType {
  Short = 'short',
  Long = 'long',
}

export const BidentConnection = [
  'VAMEDGE',
  'VAMHTF',
  'VAMHTTC',
  'VAMHTTCNA',
  'VAMHTFNR',
  'VAMHTFNA',
  'VAMBOLT',
  'VAMBOLTII',
  'VAMBOLTNA',
  'VAMSPRINTSF',
  'VAMSPRINTTC',
  'VAMSPRINTFJ',
  'VAMEDGESF',
  'VAMLIFT',
  'VAMFPO',
];

export enum VegWebsiteStatus {
  Active = 'active',
  Waiting = 'waiting',
  Draft = 'draft',
}

export enum VegLocation {
  AulnoyeAymeries = '1',
  Houston = '2',
  Aberdeen = '3',
  Singapore = '4',
  AbuDhabi = '5',
}

export const DEFAULT_LOCATION_SITE = 'AULNOYE AYMERIES';

export const WEBSITE_STATUS_GRID_MAPPING = {
  ACTIVE: GridStatus.OK,
  WAITING: GridStatus.EXPIRE,
  DRAFT: GridStatus.GREY,
};

export const DISPATCH_SUMMARY_SORT: GridSortModel = [{ field: 'licensee_number', sort: 'asc' }];

export enum DispatchBy {
  DHL = 'dhl',
  FEDEX = 'fedex',
  OTHER = 'other',
}

export enum Currency {
  EURO = 'euro',
  DOLLAR = 'dollar',
}

export enum ShippingSource {
  Dispatch = 'dispatch',
  Return = 'return',
  ReturnConfirmed = 'return_confirmed',
}
