import styled from '@emotion/styled';
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../interfaces/label-value';
import { $primaryNavy, $primaryWhite } from '../../../styles/colors';
import { ensureArray, getMultiSelectLabels } from './utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type FormInputProps = {
  name: string;
  options: LabelValue[];
  label?: string;
  labelColor?: string;
  handleChange?: Function;
  nsTranslate?: string;
  defaultValue?: any;
  removeEmptyValue?: boolean;
  withUniqueSelection?: boolean;
  defaultSelectedOptions?: any[];
  showChips?: boolean;
  maxChipWitdh?: number;
  chipCols?: number;
  maxSelections?: number;
} & Omit<SelectProps, 'variant'>;

const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
  transform: none;
  text-transform: uppercase;
`;

export const FormMultiSelect: FC<FormInputProps> = ({
  name,
  label,
  labelColor,
  options,
  handleChange,
  nsTranslate,
  defaultValue,
  removeEmptyValue,
  withUniqueSelection,
  defaultSelectedOptions,
  showChips,
  maxChipWitdh,
  chipCols,
  maxSelections,
  ...otherProps
}) => {
  const { t } = useTranslation(nsTranslate);
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const idLabel = name.trim() + '_label_id';
  // with multi select, we have the possibility to show
  // an empty option only when withUniqueSelection is true and not removeEmptyValue.
  const hasEmptyOption = withUniqueSelection && !removeEmptyValue;

  const generateOptions = (optionsSelected: string | string[]) => {
    return options.map((option) => {
      const label = option?.labelKey ? t(option.labelKey) : option.label;
      const isChecked = withUniqueSelection
        ? optionsSelected === option.value
        : optionsSelected?.includes(option.value);

      return (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={!isChecked && maxSelections && optionsSelected?.length >= maxSelections}
        >
          {withUniqueSelection ? <></> : <Checkbox checked={isChecked} />}
          <ListItemText primary={label} />
        </MenuItem>
      );
    });
  };

  /* istanbul ignore next */
  const onChangeSelect = (e: any, onChange: (value: any) => void) => {
    const controlValue = e.target.value;
    if (maxSelections && ensureArray(controlValue).length > maxSelections) {
      return;
    }
    onChange(controlValue);
    if (handleChange) {
      handleChange(controlValue, name);
    }
  };

  return (
    <FormControl fullWidth error={!!errors[name]}>
      {label && (
        <StyledInputLabel
          sx={{
            color: `${labelColor || $primaryWhite}`,
          }}
          data-testid={`${name}-test-id`}
          id={idLabel}
          focused={false}
        >
          {label}
        </StyledInputLabel>
      )}
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{
              backgroundColor: $primaryWhite,
            }}
            multiple={!withUniqueSelection}
            onChange={(e) => onChangeSelect(e, onChange)}
            value={value || getValues(name)}
            labelId={idLabel}
            id={`${name.trim()}_id`}
            renderValue={(selected) =>
              getMultiSelectLabels(
                options,
                selected,
                withUniqueSelection,
                nsTranslate ? t : undefined
              )
            }
            MenuProps={MenuProps}
            {...otherProps}
          >
            {hasEmptyOption && <MenuItem key="default_value" value=""></MenuItem>}
            {generateOptions(value)}
          </Select>
        )}
        control={control}
        name={name}
      />
      <FormHelperText className="color-error-red">
        {(errors[name] ? errors[name]?.message : '') as any}
      </FormHelperText>
      {showChips && (
        <Grid container rowSpacing={1} sx={{ marginTop: '6px' }}>
          {ensureArray(getValues(name)).map((val) => (
            <Grid
              key={val}
              item
              xs={chipCols ? 12 / chipCols : 3}
              data-testid={`${val}-grid-item-test-id`}
            >
              <Chip
                key={val}
                label={options.find((option) => option.value === val)?.label}
                onDelete={() => {
                  const newOptions = ensureArray(getValues(name)).filter(
                    (option) => option !== val
                  );
                  setValue(name, newOptions);
                  handleChange && handleChange(newOptions, name);
                }}
                color="primary"
                sx={{ backgroundColor: $primaryNavy, maxWidth: maxChipWitdh || '100px' }}
                data-testid={`${val}-chip-test-id`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </FormControl>
  );
};
