import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './en/common.json';
import documentation from './en/documentation.json';
import gauge from './en/gauge.json';
import gauge_case from './en/gauge_case.json';
import gauge_depot from './en/gauge_depot.json';
import home from './en/home.json';
import invoice from './en/invoice.json';
import licensee from './en/licensee.json';
import shipment from './en/shipment.json';
import user from './en/user.json';

export const resources = {
  en: {
    common,
    gauge,
    home,
    licensee,
    user,
    shipment,
    invoice,
    gauge_case,
    gauge_depot,
    documentation,
  },
};

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: [
    'common',
    'gauge',
    'home',
    'licensee',
    'user',
    'shipment',
    'invoice',
    'gauge_case',
    'gauge_depot',
  ],
  defaultNS: 'common',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
