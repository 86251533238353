import { InformationBox, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { LabelValue as DsLabelValue } from '@digital-at-vallourec/steel-design-system-react/dist/components/molecules/LabelValue/LabelValue';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CaseIcon from '../../assets/icons/icon-case.svg';
import { useSelectOrFetchPuAddress } from '../../hooks/useSelectOrFetchPuAddress';
import { VegCase } from '../../interfaces/case';
import { DistinctConnectionLabelValue } from '../../interfaces/distinct-product-label-value';
import { SetDetailsManagement } from '../../interfaces/set-creation';
import { getDistinctSetModelsFilter } from '../../services/api/set-model-api';
import { globalDistinctConnection } from '../../services/functions/globalDistinctConnection';
import { $green } from '../../styles/colors';
import {
  getDesignationProduct,
  mapToProductFilter,
  removeFalsyProperty,
} from '../../utils/functions';
import { ContainerCard, FormSelect } from '../shared';

interface SetInformationsProps {
  fetchSetModel?: Function;
  resetSetModel?: Function;
  gaugeCase?: VegCase;
  setInformationsDetails?: SetDetailsManagement;
}

const SetInformations = ({
  fetchSetModel,
  gaugeCase,
  resetSetModel,
  setInformationsDetails,
}: SetInformationsProps) => {
  const { t: tGauge } = useTranslation('gauge');
  const { t } = useTranslation('gauge', { keyPrefix: 'setCreation.setInformationsSection' });
  const { control, reset } = useFormContext();
  const [distinctConnectionLabelValue, setDistinctConnection] = React.useState({
    connection: [],
    od_inch: [],
    end: [],
    min_weight_max_weight: [],
    application: [],
    option: [],
  } as DistinctConnectionLabelValue);
  const { _formValues } = control;
  const [designation, setDesignation] = React.useState(
    setInformationsDetails
      ? getDesignationProduct({
          ...setInformationsDetails,
          min_weight_max_weight: setInformationsDetails.min_weight_max_weight,
        })
      : getDesignationProduct(_formValues)
  );
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();

  useEffect(() => {
    const fetchData = async () => {
      try {
        handleChangeDistinctConnection();
      } catch (error) {
        /* istanbul ignore next */
        console.error(error);
      }
    };
    fetchData();
  }, [gaugeCase?.case_id]);

  const handleChangeDistinctConnection = React.useCallback(async () => {
    if (!setInformationsDetails) {
      const { _formValues } = control;
      setDesignation(getDesignationProduct(_formValues));
      const filter = removeFalsyProperty(mapToProductFilter(_formValues));
      // when all select are filled then we fetch the set model matched
      // mean 7 selects for product designation are defined
      if (Object.keys(filter).length === 7) {
        // get all response Case + gauge model in the same response
        await fetchSetModel(filter);
      } else {
        try {
          resetSetModel();
          /* istanbul ignore next */
          const { connection } = await globalDistinctConnection(getDistinctSetModelsFilter, filter);
          /* istanbul ignore next */
          setDistinctConnection(connection);
        } catch (error) {
          /* istanbul ignore next */
          console.error(error);
        }
      }
    }
  }, []);

  return (
    <ContainerCard
      title={t('title')}
      icon={CaseIcon}
      subTitle={t('subtitle')}
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      dataTestId="setInformations-container"
      actionHeaderNode={
        <div className="tw-mr-4">
          <InformationBox
            data={[
              {
                title: t('bannerHeader'),
                name: designation,
              },
            ]}
          />
        </div>
      }
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        {setInformationsDetails ? (
          <>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.connection')}
                value={setInformationsDetails?.connection}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.od')}
                value={'' + setInformationsDetails?.od_inch}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.minWeightMaxWeight')}
                value={setInformationsDetails?.min_weight_max_weight}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.end')}
                value={setInformationsDetails?.end}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.application')}
                value={setInformationsDetails?.application}
                inputWidth={250}
              />
            </Grid>
            <Grid item xs={3}>
              <DsLabelValue
                label={tGauge('details.option')}
                value={setInformationsDetails?.option}
                inputWidth={250}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <FormSelect
                id="connection-select"
                variant="standard"
                name="connection"
                label={tGauge('details.connection')}
                options={distinctConnectionLabelValue?.connection}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                id="od-select"
                variant="standard"
                name="od_inch"
                label={`${tGauge('details.od')}`}
                options={distinctConnectionLabelValue?.od_inch}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                id="min-weight-max-weight-select"
                variant="standard"
                name="min_weight_max_weight"
                label={`${tGauge('details.minWeightMaxWeight')}`}
                options={distinctConnectionLabelValue?.min_weight_max_weight}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                id="end-select"
                variant="standard"
                name="end"
                label={tGauge('details.end')}
                options={distinctConnectionLabelValue?.end}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                id="application-select"
                variant="standard"
                name="application"
                label={tGauge('details.application')}
                options={distinctConnectionLabelValue?.application}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                id="option-select"
                variant="standard"
                name="option"
                label={tGauge('details.option')}
                options={distinctConnectionLabelValue?.option}
                handleChange={handleChangeDistinctConnection}
              />
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <Grid container columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12} className="tw-pb-4">
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            id="gauge-location-select"
            variant="standard"
            name="gauge_location"
            label={t('location')}
            options={puAddressesLabelValue}
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            id="calibration-site-select"
            variant="standard"
            name="calibration_site"
            label={t('calibrationSite')}
            options={puAddressesLabelValue}
          />
        </Grid>
        <Grid item xs={3} className="tw-flex tw-items-end">
          <SimpleButton
            data-testid="reset-set-creation-form-btn"
            onClick={() => {
              reset();
              resetSetModel();
              handleChangeDistinctConnection();
            }}
            disabled={!!setInformationsDetails}
          >
            {tGauge('reset')}
          </SimpleButton>
        </Grid>
      </Grid>
      <br />
      <Grid container columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12} className="tw-pb-4">
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {(gaugeCase?.designation || setInformationsDetails) && (
            <InformationBox
              color={$green}
              data={[
                {
                  title: t('caseDesignation'),
                  name: gaugeCase?.designation || setInformationsDetails?.case_designation,
                },
                {
                  title: t('length'),
                  name: (gaugeCase?.length || setInformationsDetails?.case_length) as any,
                },
                {
                  title: t('width'),
                  name: (gaugeCase?.width || setInformationsDetails?.case_width) as any,
                },
                {
                  title: t('weight'),
                  name: (gaugeCase?.weight || setInformationsDetails?.case_weight) as any,
                },
                {
                  title: t('height'),
                  name: (gaugeCase?.height || setInformationsDetails?.case_height) as any,
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
    </ContainerCard>
  );
};

export default SetInformations;
