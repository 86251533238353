import { differenceInDays, format, isBefore, parse, parseISO } from 'date-fns';

export function getDateFormat(date: number | Date, withTime = true): string {
  return format(date, `dd/MM/yyyy${withTime ? ' HH:mm:ss' : ''}`);
}

export function getDateNow(): string {
  return format(new Date(), 'dd/MM/yyyy HH:mm:ss');
}

export function getDateUsingFormat(date: Date, formatString: string): string {
  return format(date, formatString);
}

export function formatDate(date: string, withTime = true): string {
  return getDateFormat(getDateFromString(date), withTime);
}

export function convertToDates(dates: (Date | string)[]): Date[] {
  return dates?.map((date) => (!(date instanceof Date) ? parseISO(date) : date));
}

export function getOldestDate(dates: Date[]): Date {
  const castedDates = convertToDates(dates);
  return castedDates.length === 1
    ? castedDates[0]
    : castedDates.reduce((c, n) => (isBefore(n, c) ? n : c));
}

export function getDateFromString(date: string): Date {
  return new Date(date);
}

export function parseToValidDate(date: string, format = 'dd/MM/yyyy'): Date {
  const parsedDate = parse(date, format, new Date());
  if (!parsedDate) {
    return new Date(date);
  }

  return parsedDate;
}

export function addGMTOffsetToDate(date: Date | string): Date {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  // Divide by 60 because getTimezoneOffset returns minutes
  const offset = Math.abs(date.getTimezoneOffset()) / 60;
  date.setHours(date.getHours() + offset);

  return date;
}

export function stringDateComparator(firstDate: string, secondDate: string) {
  const castFirstDate = ![null, undefined, '-'].includes(firstDate)
    ? new Date(firstDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  const castSecondDate = ![null, undefined, '-'].includes(secondDate)
    ? new Date(secondDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1'))
    : new Date(0);
  castFirstDate.setHours(0, 0, 0, 0);
  castSecondDate.setHours(0, 0, 0, 0);
  return castFirstDate.getTime() - castSecondDate.getTime();
}

export function getDifferenceInDays(startDate: Date, endDate: Date): number {
  return differenceInDays(endDate, startDate);
}
