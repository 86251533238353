import { Grid } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useIsFirstMount } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { DistinctConnectionLabelValue } from '../../../interfaces/distinct-product-label-value';
import { getDistinctSetFilter } from '../../../services/api/set-creation-api';
import { globalDistinctConnection } from '../../../services/functions/globalDistinctConnection';
import { selectUser } from '../../../services/store/userSlice';
import { StyledGridFilter } from '../../../styles/common';
import { SetAvailabilityStatus, SetState, setIdListRegex } from '../../../utils';
import { VegWebsiteStatus } from '../../../utils/constants/booking';
import {
  mapToProductFilterFromArrays,
  removeFalsyPropertyFromArrays,
  toLabelValues,
} from '../../../utils/functions';
import { FormTextField } from '../../shared';
import { FormMultiSelect } from '../../shared/form-components/FormMultiSelect';

export function SetsManagementFilter() {
  const { t } = useTranslation('gauge');
  const user = useAppSelector(selectUser);
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();
  const { control } = useFormContext();
  const { replace } = useFieldArray({
    control: control,
    name: 'location_id',
  });
  const firstMount = useIsFirstMount();
  const [distinctSetConnectionOptions, setDistinctSetConnectionOptions] = React.useState({
    connection: [],
    od_inch: [],
    end: [],
    min_weight_max_weight: [],
    application: [],
    option: [],
  } as DistinctConnectionLabelValue);

  const websiteStatusOptions = toLabelValues(VegWebsiteStatus, 'websiteStatus.');
  const setStateOptions = toLabelValues(SetState, 'setCreation.setState.');
  const setAvailabilityStatusOptions = toLabelValues(
    SetAvailabilityStatus,
    'setCreation.setAvailabilityStatus.'
  );

  React.useEffect(() => {
    if (firstMount) {
      replace([user.depotId]);
    }
    const fetchData = async () => {
      try {
        handleChangeDistinctConnection();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: SetsManagementFilter.tsx ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, []);

  const handleChangeDistinctConnection = React.useCallback(async () => {
    const { _formValues } = control;
    const filter = removeFalsyPropertyFromArrays(
      mapToProductFilterFromArrays(_formValues) as Record<string, any[]>
    );

    const { connection } = await globalDistinctConnection(getDistinctSetFilter, filter);
    setDistinctSetConnectionOptions(connection);
  }, []);

  return (
    <Grid
      sx={{
        '&.MuiGrid-root': {
          backgroundColor: 'inherit',
        },
      }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={2}>
        <FormMultiSelect
          id="connection-select"
          name="connection"
          label={t('details.connection')}
          options={distinctSetConnectionOptions?.connection}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="od-select"
          name="od_inch"
          label={t('details.od')}
          options={distinctSetConnectionOptions?.od_inch}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="min-weight-max-weight-select"
          name="min_weight_max_weight"
          label={t('details.minWeightMaxWeight')}
          options={distinctSetConnectionOptions?.min_weight_max_weight}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="end-select"
          name="end"
          label={t('details.end')}
          options={distinctSetConnectionOptions?.end}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="application-select"
          name="application"
          label={t('details.application')}
          options={distinctSetConnectionOptions?.application}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="option-select"
          name="option"
          label={t('details.option')}
          options={distinctSetConnectionOptions?.option}
          onClose={handleChangeDistinctConnection}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="set-locationId-select"
          name="location_id"
          label={t('setsManagement.location')}
          options={puAddressesLabelValue}
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="set-webstatus-select"
          name="website_status"
          label={t('setsManagement.webStatus')}
          options={websiteStatusOptions}
          withUniqueSelection={true}
          nsTranslate="common"
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="set-state-select"
          name="set_state"
          label={t('setsManagement.setState')}
          options={setStateOptions}
          withUniqueSelection={true}
          nsTranslate="gauge"
        />
      </Grid>

      <Grid item xs={2}>
        <FormMultiSelect
          id="set-availability-status-select"
          name="availability_status"
          label={t('setsManagement.availabilityStatus')}
          options={setAvailabilityStatusOptions}
          nsTranslate="gauge"
        />
      </Grid>

      <StyledGridFilter item xs={2}>
        <FormTextField
          id="setids-list-text-fields"
          withFixedLabel
          inputProps={{
            'data-testid': 'list-set-ids-input',
          }}
          labelValue={t('setsManagement.setIds')}
          name="set_ids"
          fullWidth
          patternValue={setIdListRegex}
          patternMessage="Error syntax"
          placeholder={t('setsManagement.setIdsPlaceholder')}
        />
      </StyledGridFilter>
    </Grid>
  );
}
